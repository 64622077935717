exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-actions-dispatch-doi-tsx": () => import("./../../../src/pages/actions/dispatch-doi.tsx" /* webpackChunkName: "component---src-pages-actions-dispatch-doi-tsx" */),
  "component---src-pages-actions-payback-accountbinding-edeka-tsx": () => import("./../../../src/pages/actions/payback/accountbinding-edeka.tsx" /* webpackChunkName: "component---src-pages-actions-payback-accountbinding-edeka-tsx" */),
  "component---src-pages-actions-payback-accountbinding-trinkgut-tsx": () => import("./../../../src/pages/actions/payback/accountbinding-trinkgut.tsx" /* webpackChunkName: "component---src-pages-actions-payback-accountbinding-trinkgut-tsx" */),
  "component---src-pages-actions-payback-accountbinding-tsx": () => import("./../../../src/pages/actions/payback/accountbinding.tsx" /* webpackChunkName: "component---src-pages-actions-payback-accountbinding-tsx" */),
  "component---src-pages-actions-request-pin-tsx": () => import("./../../../src/pages/actions/request-pin.tsx" /* webpackChunkName: "component---src-pages-actions-request-pin-tsx" */),
  "component---src-pages-actions-start-login-edeka-app-tsx": () => import("./../../../src/pages/actions/start-login-edeka-app.tsx" /* webpackChunkName: "component---src-pages-actions-start-login-edeka-app-tsx" */),
  "component---src-pages-actions-start-login-trinkgut-app-tsx": () => import("./../../../src/pages/actions/start-login-trinkgut-app.tsx" /* webpackChunkName: "component---src-pages-actions-start-login-trinkgut-app-tsx" */),
  "component---src-pages-actions-start-login-tsx": () => import("./../../../src/pages/actions/start-login.tsx" /* webpackChunkName: "component---src-pages-actions-start-login-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-datenschutz-tsx": () => import("./../../../src/pages/info/datenschutz.tsx" /* webpackChunkName: "component---src-pages-info-datenschutz-tsx" */)
}

